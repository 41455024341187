import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Numbers",
  "date": "2021-03-15T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Numbers are the core contributing factor for a stable manifested universe, Everything has precise values. The significance
of numbers and mathematics is so high it prompts us to learn Maths from Pre-school to master.
When you are designing any apps or games or anything which is in digital format is ultimately made up of two signals `}{`[0,1]`}{`
Binary is the language of machines, similarly, numbers are the language of humans.
As Ramanujan said...`}</p>
    <p>{`Generation Z has so many fancy universal numbers representing various harmonize frequencies like 111, 888, 777 etc.
AFAIK it depends upon person-person; From my experience numbers were always helpful in filtering out my thoughts, increasing my intuition etc. I have been practicing this technique for almost 4 years+ and still works like a charm. `}</p>
    <p>{`I choose my lucky number viz a combination of the zodiac and planetary orientation, I consider 1,5,7 as the lucky number for myself.
and 9 as a bad omen. 1 represents the universe, 5 represents me, 7 represents my soul/spirit. I will filter out thoughts and decisions made upon summing the time. For eg If I encounter a situation that requires a choice to be made, I look upon the time '12:34' and add all digits --> 1; which means I am good to go with that idea/thought/suggestion/choice. `}</p>
    <p>{`I believe ultimately it depends upon my Mind, I don't think it can be applied to everyone's life. However, if you start believing, it will automatically happen. I am practicing this method for the last 4 years, and every time it works as intended. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      